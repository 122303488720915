<template>
  <div>
    <div class="container mt-4">
      <div class="d-flex align-items-end justify-content-between mt-5">
        <tabs v-model="tab" :options="types" size="sm" />

        <search-input v-model="keyword" class="ml-auto" placeholder="搜索关键字" />
      </div>

      <div class="d-flex justify-content-between align-items-center flex-wrap my-4">
        <small v-if="filteredInventories" class="text-secondary">
          <span v-if="keyword">关键字: {{ keyword }},</span>
          <span> 发现 {{ filteredInventories.length }} 件饰品 </span>
        </small>
        <b-pagination
          v-model="currentPage"
          class="m-0"
          :total-rows="filteredInventories.length"
          :per-page="perPage"
          first-number
          last-number
        />
      </div>

      <div class="d-flex">
        <btn class="btn btn-outline-primary px-4 py-3 ml-auto text-white" style="line-height:14px" @click="startOffer">
          发起报价
          <span v-if="selectedItems && selectedItems.length > 0">({{ selectedItems.length }})</span>
        </btn>
        <btn class="btn btn-primary px-4 py-2 ml-4" :disabled="selectedItems.length <= 0" @click="recycleAllSelected">
          回收选中 ({{ selectedItems.length }})
        </btn>
      </div>

      <div v-if="user.info.steam_id && !loading" class="inventories mt-3">
        <inventory-item
          v-for="(item, k) in pagedItems"
          :key="k"
          :disabled="item === currentInventory"
          :selected="selectedItems.includes(item)"
          :inventory="item"
          @cancel="cancel(item)"
          @withdraw="withdraw(item)"
          @recycle="recycle(item)"
          @select="addRemoveItem(item)"
        />
      </div>

      <div v-if="loading" class="inventories mt-3">
        <div v-for="i in 12" :key="`skele-${i}`" class="inventory-item" style="height: 385px;padding: 10px;">
          <b-skeleton width="100%" height="280px" class="m-0" />
          <div class="d-flex py-4" style="gap:10px">
            <b-skeleton class="m-0" width="100%" height="56px" />
            <b-skeleton class="m-0" width="100%" height="56px" />
            <b-skeleton class="m-0" width="100%" height="56px" />
          </div>
        </div>
      </div>
    </div>

    <start-offer-modal @confirm="goNewOffer" />
  </div>
</template>

<script>
import InventoryItem from './InventoryItem'
import StartOfferModal from '@/views/Offer/StartOfferModal'
import { mapGetters } from 'vuex'
import { UserService, getPropValue } from '@/services/api'
import { STEAM_LOGIN_URL } from '@/services/config'
import { errorParser } from '@/utils'

const TYPES = [
  { label: '所有装备', status: 'ALL', value: 0 },
  { label: '有效装备', status: 'INITIAL', value: 10000 },
  { label: '交易中', status: 'TRADING', value: 40000 },
  { label: '提取中', status: 'WITHDRAWING', value: 50000 },
  { label: '已提取', status: 'WITHDRAWAL', value: 60000 },
  { label: '已回收', status: 'RECYCLE', value: 20000 },
  { label: '在售不足', status: 'NOSTOCK', value: 70000 },
]

export default {
  name: 'Inventory',
  components: {
    InventoryItem,
    StartOfferModal,
  },
  data: function() {
    return {
      tab: TYPES[0],
      types: TYPES,
      inventories: [],
      currentInventory: null,
      keyword: '',
      currentPage: 1,
      perPage: 24,
      loading: false,
      selectedItems: [],
    }
  },
  computed: {
    filteredInventories() {
      let rv = this.inventories
      /**
       * INITIAL          初始       10000
       * RECYCLE          已回收      20000
       * PENDING_TRADING  准备交易    30000
       * TRADING          交易中      40000
       * WITHDRAWING      提取中      50000
       * WITHDRAWAL       已提取      60000
       * NOSTOCK          无法提取    70000
       */
      // const statuses = this.tab.map(t => t.value)
      rv = rv.filter(i => {
        // return statuses.includes(i.status)
        // TODO:
        if (this.tab.status === 'ALL') {
          return true
        }
        if (this.tab.status === 'INITIAL') {
          return i.status === 10000
        }
        if (this.tab.status === 'RECYCLE') {
          return i.status === 20000
        }
        if (this.tab.status === 'PENDING_TRADING') {
          return i.status === 30000
        }
        if (this.tab.status === 'TRADING') {
          return i.status === 40000
        }
        if (this.tab.status === 'WITHDRAWING') {
          return i.status === 50000
        }
        if (this.tab.status === 'WITHDRAWAL') {
          return i.status === 60000
        }
        if (this.tab.status === 'NOSTOCK') {
          return i.status === 70000
        }
        return i.status
      })

      if (this.keyword) {
        rv = rv.filter(i => i.item.name.toLowerCase().includes(this.keyword.toLowerCase()))
      }

      return rv
    },
    ...mapGetters(['user', 'app']),
    pagedItems() {
      const index = (this.currentPage - 1) * this.perPage
      const max = this.filteredInventories.length
      return this.filteredInventories.slice(index, Math.min(max, index + this.perPage))
    },
  },
  watch: {
    currentPage() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    addRemoveItem(item) {
      const idx = this.selectedItems.indexOf(item)
      if (idx >= 0) {
        this.selectedItems.splice(idx, 1)
      } else {
        this.selectedItems.push(item)
      }
    },
    toast(message, type = 'warning') {
      this.$bvToast.toast(message, {
        title: message,
        toaster: 'b-toaster-bottom-right',
        solid: true,
        variant: type,
      })
    },
    login() {
      window.location = STEAM_LOGIN_URL
    },
    async loadData() {
      this.loading = true
      const { data } = await UserService.getInventories()
      this.inventories = data

      this.currentInventory = null
      this.loading = false
    },
    async recycle(inventory) {
      let isSingle = true
      if (Array.isArray(inventory)) {
        isSingle = false
      }
      const text = isSingle ? inventory.item.name : '所有选中的物品'
      let totalPrice = 0
      if (isSingle) {
        totalPrice = inventory.price
      } else {
        totalPrice = inventory.reduce((a, b) => a + parseFloat(b.price), 0).toFixed(2)
      }

      const ans = await this.$bvModal.msgBoxConfirm(`你确定要回收 ${text} 吗?`, {
        title: '回收饰品',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: '确定',
        cancelTitle: '取消',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      if (!ans) {
        this.selectedItems = []
        return
      }

      let ids
      if (isSingle) {
        ids = [inventory.id]
      } else {
        ids = inventory.map(i => i.id)
      }

      try {
        await UserService.recycleItems(ids)
        this.toast(`回收成功，获得 ¥ ${totalPrice}`, 'success')
      } catch (e) {
        this.toast(errorParser(e.response).message)
      } finally {
        this.selectedItems = []
        this.$store.dispatch('LOAD_USER')
        this.loadData()
      }
    },
    recycleAllSelected() {
      this.recycle(this.selectedItems)
    },
    async withdraw(inventory) {
      try {
        this.currentInventory = inventory
        await UserService.withdrawItem(inventory.id)
        this.$bvModal.show('order-success')
        this.loadData()
      } catch (e) {
        this.toast(errorParser(e.response).message)
      } finally {
      }
    },
    async cancel(inventory) {
      try {
        this.currentInventory = inventory
        await UserService.cancelItem(inventory.id)
        this.toast('正在取消饰品', 'success')
      } catch (e) {
        this.toast(errorParser(e.response).message)
      } finally {
        this.loadData()
      }
    },
    startOffer() {
      this.$bvModal.show('start-offer')
    },
    goNewOffer(receiver) {
      const ids = this.selectedItems.map(i => i.id)
      let str = ''
      ids.forEach(i => {
        if (str) str += '&'
        str += 'i_id=' + i
      })
      const params = ids && ids.length > 0 ? `?${str}` : ''

      if (receiver.merchant_key) {
        this.$router.push({ path: `/offer/merchant/${receiver.merchant_key}/new` + params })
      } else {
        this.$router.push({ path: `/offer/${receiver.trade_url}/new` + params })
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../styles/style.scss';

.steam__login {
  &:hover {
    box-shadow: 0 0 15px #6242da;
  }

  transition: all ease 0.4s;
  background-image: linear-gradient(225deg, #9b77ef 0%, #6242da 100%);
  border-radius: 12px 2px 12px 2px;
  font-size: 16px;
  border: none;
  color: white;
  padding: 1em;
}

.no-login {
  &__text {
    margin-left: -100px;
  }

  &__img {
    flex: 0 0 700px;
    min-height: 600px;

    background-size: contain;
    background-repeat: no-repeat;
    justify-content: center;
    background-position: left center;
    background-image: url('../../assets/no-login.png');
  }

  display: flex;
  padding: 2em;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
}

.login-btn {
  margin: 2em;
  flex: 0 0 200px;
}

.order {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__desc {
    color: #6a6996;
    font-size: 12px;
  }
}

.tabs {
  display: flex;
  border-bottom: solid 1.5px #28283a;
}

.inventories {
  display: flex;
  flex-wrap: wrap;
}

.tab {
  padding: 0.5em;
  font-size: 18px;

  &.active {
    color: #fff;
    border-bottom: solid 2px $primary;
  }

  border-bottom: solid 2px transparent;
}

@media screen and (min-width: 1080px) {
  .inventory-item {
    flex: 1 25%;
    max-width: 25%;
  }
}

@media screen and (min-width: 760px) and (max-width: 1080px) {
  .inventory-item {
    flex: 1 33.33333%;
    max-width: 33.33333%;
  }
}

@media screen and (min-width: 520px) and (max-width: 760px) {
  .inventory-item {
    flex: 1 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 520px) {
  .inventory-item {
    flex: 1 100%;
    max-width: 100%;
  }
}
</style>
