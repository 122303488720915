<template>
  <div class="inventory-item">
    <div
      class="item-wrapper"
      :class="{ hovering: isHover, selected }"
      @mouseover="isHover = true"
      @mouseout="isHover = false"
      @click="select"
    >
      <svg
        v-if="selected"
        class="tick"
        width="30"
        height="27"
        viewBox="0 0 30 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.17549 25.351L0.848506 14.697C0.300967 13.6019 0.163222 12.3471 0.460167 11.1593C0.862837 9.54865 2.97994 9.17057 3.91522 10.5423L9 18L26.2893 1.17795C26.9955 0.490867 28.0857 0.390453 28.9055 0.936981C29.7487 1.49914 29.9017 2.6763 29.2303 3.43537L9.46234 25.7817C8.52349 26.843 6.80918 26.6184 6.17549 25.351Z"
          fill="url(#paint0_linear_403_2438)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_403_2438"
            x1="15.25"
            y1="0"
            x2="15.25"
            y2="28"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2EDE34" />
            <stop offset="1" stop-color="#39BA14" />
          </linearGradient>
        </defs>
      </svg>

      <!-- <div class="item__id text-secondary">
        ID {{ inventory.source_order_id }}
      </div> -->
      <div class="price text-white">{{ $currencySign }}{{ inventory.price }}</div>

      <div class="status text-secondary">
        <span> {{ statusText }}</span>
        <span v-if="inventory.error_name" class="text-warning">{{ inventory.error_name }}</span>
      </div>

      <div class="item__image">
        <img :src="imageUrl" class="item__img embed-responsive" />
      </div>

      <div class="item__bg" :class="`background--${item.rarity}`" />

      <div class="item__info">
        <div class="item__name">
          <div class="name">
            {{ nameObject.excludeWear }}
          </div>
          <div class="type">
            {{ item.type }}
          </div>
          <div class="wear">
            {{ nameObject.wear }}
          </div>
        </div>
        <div style="font-size:12px">
          来自
          <!-- <span v-if="inventory.status === 10000" class="text-danger">
            YesSkins
          </span> -->
          <span class="text-primary">
            官方商城
          </span>
        </div>
      </div>
    </div>

    <div class="action">
      <btn
        v-if="inventory.status === 50000 || inventory.status === 60000"
        class="btn-outline-secondary full text-white p-2 w-full"
        @click="$router.push(`/user/inventory/${inventory.id}`)"
      >
        查看详情
      </btn>
      <btn v-if="inventory.status === 20000" disabled class="btn-outline-secondary full text-white p-2 w-full">
        已回收
      </btn>
      <btn v-if="inventory.status === 40000" disabled class="btn-outline-secondary full text-white p-2 w-full">
        交易中...
      </btn>
      <template v-if="inventory.status === 10000">
        <btn
          class="btn btn-outline-secondary text-white p-2"
          :disabled="!inventory.can_withdraw"
          @click="emit('withdraw')"
        >
          提取
        </btn>
        <btn class="btn btn-outline-secondary text-white p-2" disabled @click="emit('cancel')">
          交易
        </btn>
        <btn
          class="btn btn-outline-secondary text-white p-2"
          :disabled="!inventory.can_recycle"
          @click="emit('recycle')"
        >
          回收
        </btn>
      </template>
      <btn
        v-if="inventory.status === 70000"
        class="btn-outline-secondary text-white p-2 full w-full"
        :disabled="!inventory.can_recycle"
        @click="emit('recycle')"
      >
        回收
      </btn>
    </div>
  </div>
</template>

<script>
import { nameFilter } from '@/utils'
import { ASSET_URL } from '@/config'

export default {
  name: 'InventoryItem',
  props: {
    inventory: Object,
    disabled: Boolean,
    selected: Boolean,
  },
  data: function() {
    return {
      isHover: false,
    }
  },
  computed: {
    nameObject() {
      return nameFilter(this.item.name)
    },
    item() {
      return this.inventory.item
    },
    imageUrl() {
      return ASSET_URL + this.inventory.item.image_url
    },
    statusText() {
      if (this.inventory.status === 20000) {
        return '已回收'
      }
      if (this.inventory.status === 40000) {
        return '交易中'
      }
      if (this.inventory.status === 50000) {
        return '提取中'
      }
      return ''
    },
  },
  methods: {
    emit(event) {
      this.$emit(event)
    },
    select() {
      if (this.inventory.can_recycle) this.emit('select')
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../styles/style.scss';

.action {
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  button:not(.full) {
    flex: 0 33.333%;
  }
}

.price {
  font-size: 1.125em;
}

.status {
  position: absolute;
  right: 20px;
  top: 20px;
  line-height: 27px;
}

.tick {
  position: absolute;
  right: 10px;
  top: 10px;
}

@each $rarity, $radio in $radios {
  .background--#{$rarity} {
    background: $radio;
  }
}

@each $rarity, $color in $rarities {
  .background--#{$rarity} {
    border-color: $color !important;
  }
}

.inventory-item {
  height: 385px;
  padding: 10px;
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
}

.item {
  position: relative;
  cursor: pointer;
  user-select: none;

  &-wrapper {
    width: 100%;
    height: 295px;
    padding: 20px;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    background: #141a24;

    &.hovering {
      /* box-shadow: 0 0 10px 0px $primary; */
      .item__bg {
        background: $primary-radio;
        border-color: $primary !important;
      }
      .item__image {
        transform: scale(1.15);
      }
      .item__info {
        .name {
          color: $primary;
        }
        .oversize {
          color: $primary;
        }
      }
    }
    &.selected {
      .item__bg {
        background: $primary-radio;
        border-color: $primary !important;
      }
      .item__info {
        .name {
          color: $primary;
        }
        .oversize {
          color: $primary;
        }
      }
    }
  }

  &__id {
    position: absolute;
    right: 10px;
  }
  &__bg {
    width: 120%;
    height: 200px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 4px solid $secondary;
  }
  &__name {
    white-space: nowrap;

    .wear {
      height: 16px;
      font-size: 11px;
      color: #666;
    }
    .type {
      color: #a5a5a5;
      font-size: 0.8em;
    }
  }
  &__info {
    position: relative;
    color: #fff;
    font-size: 14px;
    padding: 0 1em;
  }
  &__img {
    /* height: 100%; */
    -webkit-user-drag: none;
    &:hover {
      animation: bounce 4s ease-in-out 1s infinite alternate;
    }
  }
  &__image {
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    transition: 0.3s ease;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px) translateX(1px);
  }
  100% {
    transform: translateY(10px);
  }
}
</style>
